<template>
  <div class="depart-box ">
    <div class="left-box">
      <framework
          @changeDepart="handlDepartChange"
          :reload.sync="reload"
      ></framework>
    </div>
    <div class="right-table">
      <framework-list
          :departData="departData"
          @reload="reloadData"
      ></framework-list>
    </div>

  </div>
</template>
<script>
import framework from "@/views/Department/department/framework";
import frameworkList from "@/views/Department/department/frameworkList";

export default {
  data() {
    return {
      departData: "",
      reload: false
    };
  },
  watch:{

  },
  components: {
    framework,
    frameworkList
  },
  created () {


  },
  mounted() {
  },
  methods: {
    handlDepartChange(data) {
      this.departData = data;
    },
    reloadData() {
      this.reload = true;
    }
  }
};
</script>
<style lang="less" scoped>
.depart-box {
  width: 100%;
  background: #F4F6F8;
  .left-box {
    width: 240px;
    //border-right: 1px solid #E6E6E6;
    //flex: 0 0 260px;
    //min-height: 810px;
    //background: #F4F6F8;
    position: absolute;
  }

  .right-table {
    //flex: 1;
  }
}
</style>
