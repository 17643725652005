<template>
    <div>
      <div class="search-form">
        <el-autocomplete
            placeholder="请输入搜索关键字"
            :fetch-suggestions="remoteMethod"
            v-model="keywords"
            @select="handleSelect"
            style="width: 92%;margin:0 15px;height:32px;"
        >
          <template slot-scope="{ item }">
            <div class="tree-item">
              <span><i class="el-icon-folder"></i></span>
              <span class="tree-title" v-if="allIsWx3=='true'">
                <span v-if="item.dep_id===9999999">{{item.dep_name}}</span>
                <wxComponent v-else type="departmentName" :openid="item.dep_id"  />
              </span>
              <span class="tree-title" v-else>
                {{item.dep_name}}
              </span>
            </div>
          </template>

        </el-autocomplete>
      </div>
      <el-scrollbar :vertical="true" class="framework" style="height:600px;">
        <el-tree
            :data="data"
            node-key="dep_id"
            :load="loadNode"
            @node-expand="getTreeLoading"
            v-loading="treeLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(244, 246, 248, 1)"
            lazy
            ref="tree"
            :props="defaultProps"
            :expand-on-click-node="false"
            @node-click="handleDepartClick"
        >
      <span class="custom-tree-node" slot-scope="{ data }">
        <span v-if="allIsWx3=='true'">
          <i class="el-icon-folder"></i>
          <span v-if="data.dep_id===9999999">{{data.dep_name}}</span>
           <wxComponent v-else type="departmentName" :openid="data.dep_id"     />
        </span>
        <span v-else>
          <i class="el-icon-folder"></i>
          <span>{{data.dep_name}}</span>
        </span>
      </span>
        </el-tree>
      </el-scrollbar>
    </div>
</template>
<script>
import { getDepart } from "@/api/workbench";
import  wxComponent from "@/components/wx";
import {getConfigApi} from "../../../api/config";
// import {otherBrowser, wxAgentConfig, wxConfig} from "../../../utils/wx";
export default {
  props: ["reload"],
  components: {
    wxComponent
  },
  data() {
    return {
      WWOpenData:window.WWOpenData,
      data: [],
      getTree:[],
      defaultProps: {
        id:'dep_id',
        children: "child",
        label: "dep_name",
        // children: [],
        isLeaf: "leaf"
      },
      keywords:'',
      treeLoading:false,
      allIsWx3:true,
    };
  },
  watch: {
    reload(val) {
      if (val) {
        this.getDepartData();
      }
    }
  },
  computed: {
    _reload: {
      get() {
        return this.reload;
      },
      set() {
        this.$emit("update:reload", false);
      }
    }
  },
  created() {
    this.allIsWx3=sessionStorage.getItem('is_wx3');
  },
  // async mounted() {
  //   if(this.allIsWx3=='true'){
  //     if(process.env.NODE_ENV === 'production'){
  //       if (/MicroMessenger/i.test(navigator.userAgent)) {
  //         const {data: getConf} = await getConfigApi({
  //           type: 1,
  //           current_url: window.location.origin + window.location.pathname
  //         })

  //         const getConfig = {
  //           beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
  //           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //           appId: getConf.corpid, // 必填，企业微信的corpID
  //           timestamp: getConf.timestamp, // 必填，生成签名的时间戳
  //           nonceStr: getConf.noncestr, // 必填，生成签名的随机串
  //           signature: getConf.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
  //           jsApiList: ['WWOpenData'],
  //         }
  //         await wxConfig(getConfig)
  //         const {data} = await getConfigApi({type: 2, current_url: window.location.origin + window.location.pathname})
  //         console.log(data,'type2')
  //         const config = {
  //           beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
  //           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //           corpid: data.corpid, // 必填，企业微信的corpID
  //           agentid: data.agentid,
  //           timestamp: data.timestamp, // 必填，生成签名的时间戳
  //           nonceStr: data.noncestr, // 必填，生成签名的随机串
  //           signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
  //           jsApiList: ['WWOpenData'],
  //         }

  //         wxAgentConfig(config).then(_ => {
  //           console.log('wxAgentConfig')
  //           // eslint-disable-next-line no-undef
  //           WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
  //         })


  //       } else {
  //         otherBrowser()
  //       }
  //     }
  //   }

  // },
  methods: {
    handleSelect(item) {
      console.log(item);
      this.$emit("changeDepart", item);
    },
    getTreeLoading(){
      this.treeLoading=true;
      setTimeout(()=>{
        this.treeLoading=false;
      },2000)
    },
    remoteMethod(query, cb) {
      query !== '' ? getDepart({dep_id:0,keywords: query}).then(res => cb(res.data.depart)) : cb([])
    },
    // 异步树叶子节点懒加载逻辑
    loadNode(node, resolve) {
      // 一级节点处理
      if (node.level === 0) {
        this.requestTree(resolve);
      }
      // 其余节点处理
      if (node.level >= 1) {
        this.getIndex(node).then(res=>{
          return resolve(res)
        });
      }
    },
    //异步加载叶子节点数据函数
    getIndex(node) {
      return getDepart({dep_id:node.data.dep_id}).then(res=>{
          return res.data.depart
        })
    },
    //首次加载一级节点数据函数
    requestTree(resolve) {
      getDepart({dep_id:0}).then(out => {
            let data = out.data.depart;
            resolve(data);
          })
          .catch(error => {
            console.log(error)
          });
    },
    //   点击节点
    handleDepartClick(data) {
      this.$emit("changeDepart", data);
    }
  }
};
</script>
<style lang="less" scoped>
.search-form{
  padding: 24px 0 8px;
  .tree-item {
    display: flex;
    align-items: center;
  }
  /deep/.el-input__inner{
    height: 32px;
  }
  .tree-title {
    margin-left: 5px;
  }
}
.framework {
  /deep/.el-tree {
    font-size: 14px;
    background: #F4F6F8;
  }
  .is-horizontal {
    display: none;
  }
  /deep/.el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 0 0 0 6px;
    }
  }
  /deep/.el-tree>.el-tree-node{
    min-width: 100%;
    display:inline-block;
  }
}
</style>
