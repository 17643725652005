<template>
  <el-dialog width="400px" title="编辑用户" :visible.sync="editShow" class="pickUserBox">
    <div slot="title" class="header-title">
      <span class="title-name">编辑用户</span>
    </div>
    <el-form
      :model="formData"
      v-loading="loading"
      ref="editForm"
      class="editForm"
      size="mini"
      label-position="left"
    >
      <el-form-item label="账号" prop="open_userid" style="display: flex;justify-content: flex-start;">
        <el-input disabled v-model="formData.userid"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="userid" style="display: flex;justify-content: flex-start;">
        <div class="input-component" disabled>
          <wxComponent v-if="allIsWx3=='true'" type="userName" :openid="formData.userid" ref="wx"/>
          <span v-else>{{formData.username}}</span>
        </div>
      </el-form-item>
      <el-form-item label="角色" prop="role_id" style="display: flex;justify-content: flex-start;">
        <el-select v-model="formData.role_id" placeholder="请选择角色">
          <el-option
            v-for="(item, index) in roleList"
            :key="index"
            :label="item.role_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="state" style="display: flex;justify-content: flex-start;">
        <el-radio v-model="formData.state" :label="2">启用</el-radio>
        <el-radio v-model="formData.state" :label="4">禁用</el-radio>
      </el-form-item>
      <el-form-item class="btns" style="display: flex;justify-content: flex-start;">
        <el-button size="mini" @click="editShow = false">取消</el-button>
        <el-button type="primary" size="mini" @click="saveData(formData)">确定</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { setUserRole } from "@/api/workbench";
import wxComponent from "@/components/wx";

export default {
  props: ["show", "roleList", "data", "type"],
  data() {
    return {
      loading: true,
      uid: "",
      formData: {
        open_userid: "",
        userid: "",
        role_id: "",
        state:2,
        username:'',
      },
      allIsWx3:true,
    };
  },
  components:{
    wxComponent
  },
  watch: {
    data() {
      this.setData();
    }
  },
  created() {
    this.allIsWx3=sessionStorage.getItem('is_wx3');
    setTimeout(() => {
      if (this.data) this.setData();
      this.loading = false;
    }, 1000);
  },
  computed: {
    editShow: {
      get: function() {
        return this.show;
      },
      set: function(val) {
        this.$emit("update:show", val);
         this.$refs['editForm'].clearValidate()
      }
    }
  },
  methods: {
    setData() {

        this.formData.open_userid = this.data.open_userid;
        this.formData.userid = this.data.userid;
        this.formData.role_id = this.data.role_id;
        this.formData.username = this.data.name;
        if(this.data.state===1 || this.data.state===2){
          this.formData.state = 2;
        }else{
          this.formData.state = 4;
        }
        WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
    },
    saveData(form) {//修改信息
      setUserRole(form).then(res => {
        if (res.code ===200) {
          this.$message({
            type:'success',
            message:"修改成功"
          });
          this.editShow = false;
          setTimeout(() => {
            this.$emit("editSuccess", form);
          }, 1000);
        } else {
          this.$message.error(res.msg);
          this.editShow = false;
        }
      });

     
    }
  }
};
</script>
<style lang="less" scoped>
.pickUserBox {
  /deep/.el-dialog{
    /deep/.editForm {
      // width: 70%;
      margin: 0 10%;

      .el-form-item {
        //display: flex;
        //justify-content: flex-start;
        .el-form-item__label {
          width: 30%;
          font-size: 12px;
        }
        .el-form-item__content {
          .input-component{
            width: 178px;
            background: #F5F7FA;
            cursor: not-allowed;
            padding: 0 15px;
            color:#C0C4CC;
            border-radius: 4px;
            border:1px solid #DCDFE6;
          }
          /deep/.el-input{
            //width: 178px;
          }
        }
      }
      .el-select,.el-date-editor.el-input {
        width: 178px;
      }
      .el-form-item.btns{
        margin-top: 30px;
        text-align: center;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

}
</style>
