<template>
  <div class="user-list_box">
    <div class="flex-bet-top">
      <h3 class="company fz20">{{ corp_name }}</h3>
      <el-button class="synch" :loading="btnOptions.departLoading" v-if="synchronization_organizational"
                 @click="getLinkSecret" size="small">{{ btnOptions.departTxt }}
      </el-button>
    </div>
    <el-form :model="searchInfo" size="small" :inline="true">
      <el-form-item class="sel-role">
        <el-select
            v-model="searchInfo.role_id"
            placeholder="请选择角色"
            class="role"
            @change="handleSelect(searchInfo.role_id,searchInfo.keywords,searchInfo.state)"
            clearable
        >
          <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.role_name"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="sel-role">
        <el-select
            v-model="searchInfo.state"
            placeholder="请选择状态"
            @change="handleSelect(searchInfo.role_id,searchInfo.keywords,searchInfo.state)"
            clearable
        >
          <el-option
              v-for="item in stateList"
              :key="item.id"
              :label="item.state_name"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="sel-key">
        <el-input
            v-model="searchInfo.keywords"
            placeholder="请输入姓名"
            @input="clearInput(searchInfo.role_id,searchInfo.keywords,searchInfo.state)"
            @keyup.enter.native="handleSearch(searchInfo.role_id,searchInfo.keywords,searchInfo.state)"
        ></el-input>
        <i @click="handleSearch(searchInfo.role_id,searchInfo.keywords,searchInfo.state)" class="el-icon-search search-btn"></i>
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="exportRelation" v-if="import_user_relation">导入关联关系</el-button>
      </el-form-item>
    </el-form>
    <div class="main-msg">
      <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.8)"
          :data="tableData"
          style="width:100%;"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" ></el-table-column>
        <el-table-column prop="name" label="姓名" >
          <template slot-scope="scope">
            <wxComponent v-if="allIsWx3=='true'"  type="userName" :openid="scope.row.userid" ref="wx"/>
                      <div v-else>{{scope.row.name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="userid" label="账号" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="mobile" label="手机号" >
          <template slot-scope="scope">
            <span>{{ scope.row.mobile ? scope.row.mobile : '暂无' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="部门" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="allIsWx3=='true'">
              <div v-if="scope.row.main_department===9999999">{{scope.row.main_department}}</div>
              <wxComponent v-else type="departmentName" :openid="scope.row.main_department"/>
            </div>
            <div v-else>
              {{scope.row.user_depart}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="role_name" label="角色" >
        </el-table-column>
        <el-table-column prop="state" label="状态" >
          <template slot-scope="scope">
            <span>{{scope.row.state===1 || scope.row.state===2 ? '启用' : '禁用'}}</span>
          </template>
        </el-table-column>
        <el-table-column v-for="(item,index) in other_set"  :key="index" :property="item.key_name">
          <template slot="header" slot-scope="scope">
                <el-dropdown trigger="click">
                    <el-dropdown-menu slot="dropdown">
                      <!-- <el-popconfirm title="这是一段内容确定删除吗？" :confirm="deleteTable(item)"> -->
                      <el-popover
                          placement="top"
                          width="160"
                          v-model="deleteVisible">
                          <p>是否确定删除</p>
                          <div style="text-align: right; margin-top:10px;">
                            <el-button size="mini" type="text" @click="deleteVisible = false">取消</el-button>
                            <el-button type="primary" size="mini" @click="deleteTable(item)">确定</el-button>
                          </div>
                        <el-dropdown-item slot="reference" >删除</el-dropdown-item>
                      </el-popover>
                      <!-- </el-popconfirm> -->
                      <el-dropdown-item @click.native="editTable(item)">编辑</el-dropdown-item>
                    </el-dropdown-menu>
                    <div style="display:flex;">
                      <span style="color:#6BB7FF;">{{item.name}}<i class="el-icon-setting" style="margin-left:4px;font-size:15px;color:#6BB7FF;"></i></span>
                    </div>
                    
                </el-dropdown>
                <!-- <el-popover
                  placement="top-start"
                  title="标题"
                  width="200"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"> 
                  <i class="el-icon-setting" style="margin-left:4px;font-size:15px;color:#6BB7FF;"></i>
                </el-popover> -->
              
              
             <!-- <div style="display:flex;"> 
                 
              </div> -->
         
         
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row[item.key_name]">{{scope.row[item.key_name]}}</span>
            <span v-else>- -</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link icon-more"><i class="el-icon-more"></i></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeCom(scope.row,'a')">查看</el-dropdown-item>
                <el-dropdown-item v-if="edit_user && myRole(scope.row)" :command="beforeCom(scope.row,'b')">编辑</el-dropdown-item>
                <el-dropdown-item v-if="btntype==2" :command="beforeCom(scope.row,'c')">更新工作台</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination" v-show="tableData.length">
        <el-pagination
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
            background
            layout="prev, pager, next,total,sizes"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="pageInfo.limit"
            :total="pageInfo.total"
        ></el-pagination>
      </div>

    <!--    编辑人员详情-->
    <edit-info
        :show.sync="editShow"
        :roleList="roleList"
        :data="editData"
        :type="type"
        @editSuccess="editSuccess"
        @update="getUserData"
    ></edit-info>
    <!-- 同步组织架构 -->
    <el-dialog width="600px" class="tb-dialog" top="15vh" title="同步组织架构" :visible.sync="outerVisible">
      <!-- 外层 -->
      <div class="TBbox">
        <!-- <div class="TBbox_text " v-if="allIsWx3=='true'">
          <p class="fz16">
            <i class="el-icon-warning" style="color:#FAB223;font-size: 24px;margin-right: 2px"></i>同步组织机构
          </p>
          <p style="color:rgba(0,120,240,1);" @click="innerVisible = true">如何获取通讯录secret?</p>
        </div>
        <div class="flex-bet" v-if="allIsWx3=='true'"><el-input
            class="TBbox_input"
            style="width:90%"
            v-model="input"
            :disabled="disabled"
            placeholder="请输入通讯录secret以同步部门、人员名称"
        ></el-input>
          <el-button @click="editTbInput" v-show="getInput" type="text">修改</el-button>
        </div> -->
        <div v-if="allIsWx3=='false'" style="color:#606266;line-height: 23px;">请确认企业微信后台应用可见范围内，包含您本人，<br/>否则同步后您将被禁止登陆，确定同步吗？</div>
        <div v-else style="color:#606266;line-height: 23px;">您当前系统为SASS版本，如果应用可见范围有变动，系统会自动同步无需手动同步，<br/>该功能有频率限制，请勿频繁操作！</div>
         <el-button class="add-app" size="small" @click="dialogVisibleInfo = true" style="margin-top:20px;">录入通讯录secret</el-button>
      </div>
      <el-dialog
          width="80%"
          top="10vh"
          title="通讯录获取secret方法"
          :visible.sync="innerVisible"
          append-to-body
          class="secret-dialog"
      >
        <div class="TBbox-in">
          <div class="i-lift" @click="num--">
            <i class="el-icon-arrow-left" v-show="num>0"></i>
          </div>
          <div>
            <img class="tb-img" v-for="(imgs,i) in imglist" :key="i" :src="imgs.img" alt v-show="i==num"/>
          </div>
          <div class="i-right" @click="num++">
            <i class="el-icon-arrow-right" v-show="num<4"></i>
          </div>
        </div>
        <div class="TBbox-in-text" v-for="(imgs,i) in imglist" :key="i" v-show="i==num">
          <h2>{{ imgs.title }}</h2>
          <p>{{ imgs.text }}</p>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="innerVisible = false">关闭</el-button>
        </div>
        <!-- 内层 -->
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="outerVisible = false">取 消</el-button>
        <el-button size="small" @click="Tbparams()" type="primary">确定</el-button>
      </div>
    </el-dialog>

    <!--    导入-->
    <el-dialog width="693px" title="关联关系模板"  :visible.sync="exportVisible" class="export-dialog">
      <el-main v-loading="dialogLoad" :element-loading-text="loadingText">
        <div class="flex-start tips">
          <div>点击生成模板后，下载对应模板完成对应数据编辑后,请在下方完成上传。</div>
          <el-button size="mini" @click="exportInfo" v-loading.fullscreen.lock="fullscreenLoading">生成模板</el-button>
        </div>
        <div v-show="isMsgShow" style="margin-top: 10px">生成模板任务已加入异步队列，查看任务进度请点击<el-button size="mini" style="margin-left:10px;" @click="goToTask">异步队列</el-button></div>
        <el-upload
            class="upload-demo flex-cent-col upload-xlx"
            action=""
            drag
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadFile"
            >
          <div v-if="fileUrl">
            <img src="@/assets/images/uploadxlx.svg" alt="xlsx文件">
            <div class="xlx-name">{{fileName}}</div>
            <el-button>重新上传</el-button>
          </div>
          <div v-else>
            <img src="@/assets/images/xle.png" alt="xlsx文件">
            <div class="upload-this fz16">点击或将文件拖拽到这里上传</div>
            <div class="support">支持xls、xlsx文件格式</div>
            <div class="upload-btn">上传文件</div>
          </div>

        </el-upload>
      </el-main>
    </el-dialog>

    <!--    查看详情-->
    <el-drawer
        :modal=false
        title="关系对照表"
        size="320px"
        :visible.sync="drawer">
      <div class="demo-drawer__content">
        <div class="drawer-main">
          <div class="drawer-top">
            <span class="fz20">
              <wxComponent v-if="allIsWx3=='true'" type="userName" :openid="checkUserId"/>
              <span v-else>{{checkUser}}</span>
            </span>
            <div class="top-username fz14">ID:{{checkUserId}}</div>
          </div>
          <div class="system-title">系统对应关系</div>
          <div class="system-list">
            <div v-if="systemList.length>0">
              <div class="system-item flex-bet" :class="item.status===1 ? 'normal' : 'disable'"
                   v-for="(item,index) in systemList" :key="index">
                <div class="system-name">{{item.name}}</div>
                <div class="system-keyId" v-show="item.noEdit">{{item.key_id}}<i @click="editKeyId(item)" v-show="item.status===1" class="el-icon-edit"></i></div>
                <div class="edit-input" v-show="!item.noEdit"><el-input v-model="editInput" size="mini" @blur="updateKey(item)" placeholder="请输入内容"></el-input></div>
              </div>
            </div>
            <div v-else class="flex-cent-col">
              <img src="@/assets/images/noData.png" alt="暂无数据" style="margin:30px 0">
              <div>暂无数据</div>
            </div>
          </div>
        </div>
        <div class="demo-drawer__footer flex-start">
          <div class="drawer-cancel" @click="drawer=false">取 消</div>
          <div class="drawer-sure" @click="drawer=false" >确 定</div>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="40%">
        <div style="display:flex;flex-direction:column;">
           <el-input v-model="enterpriseInfot.name" style="width:100%;" placeholder="oa系统"></el-input>
           <!-- @change="changeCommon_userid" -->
           <el-switch :active-value="1" :inactive-value="0" v-model="enterpriseInfot.common_userid" @change="changeCommon_userid" style="margin-top:20px;" active-text="是否同企业微信账号保持一致"></el-switch>
           <span style="margin-top:20px;">开启后，新用户自动将企业微信账户同步，不需要手动编辑/导入</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handClickSetOtherRelation">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="通讯录secret" :visible.sync="dialogVisibleInfo" width="600px">
        <span style="color:#666666;">如果需要自定义自建应用，需要录入通讯录secret后才能支持</span>
        <el-input v-model="input" placeholder="请输入通讯录secret" style="margin-top:20px;"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleInfo = false">取 消</el-button>
          <el-button type="primary" @click="addLinkSecret">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import editInfo from "@/views/Department/department/editInfo";
import {getUser, roleList, departSync, exportUser, getExportId,getLinkSecret,addLinkSecret,uploadXls,userRelation,updateOtherUser,exportUserZj,isTasking,setOtherRelation,delRelation,updateworkbench,checkworkbench} from "@/api/workbench";
import wxComponent from "@/components/wx";
import {getConfigApi} from "../../../api/config";
import XLSX from 'xlsx'
// import {otherBrowser, wxAgentConfig, wxConfig} from "../../../utils/wx";

export default {
  props: ["departData"],
  components: {
    editInfo, wxComponent
  },
  data() {
    return {
      loadingText:'',
      allIsWx3:true,
      corp_name: '',
      num: 0,
      drawer:false,//查看详情弹窗
      checkUserId:'',
      checkUser:'',
      editInput:'',
      systemList:[],//系统列表
      other_set:[],
      imglist: [
        {
          img: require("@/assets/images/secret_one.png"),
          title: "第一步",
          text:
              "打开企业微信管理后台，点击「登录」，以管理员身份企业微信扫码登录。",
        },
        {
          img: require("@/assets/images/secret_two.png"),
          title: "第二步",
          text:
              "登录成功后，在菜单栏点击「管理工具」，进入以后点击「通讯录同步」。",
        },
        {
          img: require("@/assets/images/secret_three.png"),
          title: "第三步",
          text: "进入该界面后，在secret栏，直接复制对应链接。",
        },
        {
          img: require("@/assets/images/secret_four.png"),
          title: "第四步",
          text: "将复制的链接粘贴至secret输入框，点击「确定」同步成功！",
        },
        {
          img: require("@/assets/images/secret_five.png"),
          title: "第五步",
          text: "将复制的链接粘贴至secret输入框，点击“确定”同步成功！",
        },
      ],
      stateList:[
        {id:'',state_name:'全部'},
        {id:'1',state_name:'启用'},
        {id:'3',state_name:'禁用'},
      ],
      input: "", //同步架构
      disabled:true,
      outerVisible: false, //外层
      getInput:false,
      innerVisible: false, //内层
      exportVisible:false,//导入模板弹窗
      searchInfo: {
        keywords: null,
        dep_id: 1,
        role_id: null,
        state:'',
      },
      roleList: [],
      tableData: [],
      loading: false,
      pageInfo: {
        page: 1,
        limit: 10,
        total: 0,
      },
      params: {
        dep_id: 1,
        role_id: null,
        page: 1,
        limit: 10,
        k_field: 'name',
        keywords: '',
      },
      editShow: false,
      editData: "",
      selectNumber: 0,
      type: "",
      selectData: [],
      btnOptions: {
        departLoading: false,
        departTxt: "从企业微信同步",
      },
      sync_organization: false, //同步架构
      isDownload:false,
      downLoadVal:'',
      total_user:10,
      modelList:[],
      noUpload:true,
      downLoadModel:false,
      fullscreenLoading:false,
      dialogLoad:false,
      fileUrl:'',
      fileName:'',
      isMsgShow:false,
      dialogVisible:false,//编辑开关状态\
      enterpriseInfot:{},
      deleteVisible:false,
      dialogVisibleInfo:false,
      link_secret:'',
      btntype:2
    };
  },
  watch: {
    departData(val) {
      this.searchInfo.dep_id = val.dep_id;
      this.params.dep_id = val.dep_id;
      this.pageInfo.page = 1;
      this.params.page = 1;
      this.params.role_id = null;
      this.params.keywords = '';
      this.getUserData();
    },
  },
  // async mounted() {
  //   if(this.allIsWx3==='true'){
  //     if(process.env.NODE_ENV === 'production'){
  //       if (/MicroMessenger/i.test(navigator.userAgent)) {
  //         const {data: getConf} = await getConfigApi({
  //           type: 1,
  //           current_url: window.location.origin + window.location.pathname
  //         })

  //         const getConfig = {
  //           beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
  //           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //           appId: getConf.corpid, // 必填，企业微信的corpID
  //           timestamp: getConf.timestamp, // 必填，生成签名的时间戳
  //           nonceStr: getConf.noncestr, // 必填，生成签名的随机串
  //           signature: getConf.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
  //           jsApiList: ['WWOpenData',"selectEnterpriseContact","openEnterpriseChat"],
  //         }
  //         await wxConfig(getConfig)
  //         const {data} = await getConfigApi({type: 2, current_url: window.location.origin + window.location.pathname})

  //         const config = {
  //           beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
  //           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //           corpid: data.corpid, // 必填，企业微信的corpID
  //           agentid: data.agentid,
  //           timestamp: data.timestamp, // 必填，生成签名的时间戳
  //           nonceStr: data.noncestr, // 必填，生成签名的随机串
  //           signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
  //           jsApiList: ['WWOpenData',"selectEnterpriseContact","openEnterpriseChat"],
  //         }

  //         wxAgentConfig(config).then(_ => {
  //           WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
  //         })
  //       } else {
  //         otherBrowser()
  //       }
  //     }
  //   }
  // },
  created() {
    let appInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.corp_name = appInfo.company
    this.getUserData();
    this.getRoleData();
    this.checkbtn()
    this.allIsWx3=sessionStorage.getItem('is_wx3');
  },
  filters: {
    formatAvatar(img, rootPath) {
      let imgUrl = "",
          path = `http://${rootPath.split("/")[2]}`;
      if (img.includes("http") || img.includes("https")) {
        imgUrl = img;
      } else {
        imgUrl = path + img;
      }
      return imgUrl;
    },
  },
  computed: {
    synchronization_organizational(){
      return this.$store.state.listData.synchronization_organizational
    },
    myRole(){
      return function(row){
        return !row.is_creator
      }
    },
    edit_user(){
      return this.$store.state.listData.edit_user
    },
    import_user_relation(){
      return this.$store.state.listData.import_user_relation
    },
    getRootPath() {
      return this.$store.state.common.rootPath;
    },
    checkRole() {
      return function (id) {
        this.roleList.map(item => {
          if (id === item.id) {
            return item.role_name
          }
        })
      }
    }
  },
  methods: {
   async checkbtn(){
    const {code,data} = await checkworkbench()
     if(code==200){
      this.btntype= data.type
     }
   },
    //table表头头部设置
     renderHeader(h, { column, $index }) {
      return h(
        // 添加一个span    标签 class类为renderHead 父级标签 最外层标签
        'span',
        {
          class: 'renderHead'
        },
        [
          // 把节点添加为table的头部信息单元格中
          h('span', column.label), // table 显示的label值 
          //  第二个节点 el-icon-question 标签（elemen图标） el-tooltip 为显示数据容器element中
          h('el-tooltip',
            {
              attrs: {
                content: '操作',
                placement: 'top'
              }
            },
            // 添加span节点 定义class为el-icon-question
            [
              h('span', [
                h('span', {
                  attrs: { class: 'el-icon-setting' }
                })
              ])
            ],
          )
        ],
      )
    },
    //打开通讯录
    addLinkSecret(){
      // if(this.link_secret == ''){
      //   this.$message({message: '请先输入录入通讯录secret',type: 'warning'});
      // } 
      addLinkSecret({link_secret:this.input}).then(res=>{
          if(res.code == 200){
            this.dialogVisible = false
            this.link_secret  = ''
            this.$message({
              type: 'success',
              message: '录入成功!'
            });
          }
      }).catch(err=>{
          this.$message({message:res.message,type: 'warning'});
      })
    },
    getUserData() {// 部门用户列表
      let data = this.params;
      this.loading = true;
      getUser(data).then((res) => {
        //分页标记
        if (res.code === 200) {
          let data=res.data;
          data.other_attr.map(other=>{
            data.data.map(item=>{
              item.title=other.name
              item.key_name=other.key_name
            })
          })
          this.other_set=data.other_attr;
          console.log(this.other_set,'this.other_set')
          this.other_set.splice(this.other_set.length)
          // this.$set(this.other_set,data.other_attr)
          this.tableData = data.data;
          this.pageInfo.total = parseInt(res.data.total);
          this.$forceUpdate();
          console.log("ashdhagsdjhaskhdsadjasdhjk")
          setTimeout(()=>{
            // if(this.allIsWx3==='true'){
            //   WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
            // }
            this.loading = false;
            this.$forceUpdate();
          },1500)
        } else {
          this.$message.error(res.message);
          this.tableData = [];
          this.loading = false;
        }

      })
          .catch((err) => {
            this.params.keywords = '';
            this.params.role_id = '';
            console.log(err);
            this.loading = false;
          });
    },
    getRoleData() {// 角色列表
      roleList().then((res) => {
        if (res.code === 200) {
          this.roleList = res.data;
        }
      })
          .catch((err) => {
            console.log(err);
          });
    },
    handleSearch(role_id, keywords) {// 查询
      if (role_id || keywords) {
        this.params.keywords = keywords;
        role_id
            ? (this.params.role_id = role_id)
            : (this.params.role_id = null);
        this.pageInfo.page = 1;
        this.params.page = 1;
        this.getUserData();
      } else {
        this.$message({
          message: '请选择角色或输入搜索关键字',
          type: 'warning'
        })
        return false;
      }
    },
    handleSelect(role_id, keywords,state) {
      console.log(this.params, 'this.params')
      this.params.keywords = keywords;
      role_id
          ? (this.params.role_id = role_id)
          : (this.params.role_id = null);
      this.pageInfo.page = 1;
      this.params.page = 1;
      this.params.state=state;
      this.getUserData();
    },
    clearInput(role_id, keywords,state) {//清空搜索返回全部
      if (!keywords) {
        this.params.keywords = null;
        role_id
            ? (this.params.role_id = role_id)
            : (this.params.role_id = null);
        this.pageInfo.page = 1;
        this.params.page = 1;
        this.params.state=state;
        this.getUserData();
      }
    },
    handlePageChange(page) {
      this.pageInfo.page = page;
      this.params.page = page;
      this.getUserData();
    },
    handleSizeChange(size){
      this.pageInfo.limit = size;
      this.params.limit = size;
      this.getUserData();
    },
    beforeCom(row, command) {//传参
      return {
        'row': row,
        'command': command,
      }
    },
    handleCommand(command) {//更多操作
      switch (command.command) {
        case "a"://查看
          this.handleCheck(command.row)
          break;
        case "b"://编辑
          this.handleEdit('single', command.row, this.roleList)
          break;
        case "c"://更新工作台
          this.updatework(command.row)
          break;
      }
    },
    async updatework(val){
     const {message,code} = await updateworkbench({userid:val.userid})
      if(code){
          this.$message({
              type:'success',
              message:message
            })
      }else{
         this.$message({
              type:'error',
              message:message
            })
      }
    },
    handleCheck(row){//查看
      console.log(row)
      this.checkUserId=row.userid;
      this.checkUser=row.name;
      // this.drawer=true;
      userRelation({userid:row.userid}).then(res=>{
        // console.log(res)
      res.data.other_relations.map(item=>{
        item.noEdit=true;
      })
        this.systemList=res.data.other_relations;
        this.drawer=true;
        if(this.allIsWx3==='true'){
        WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));

        }
      }).catch(err=>{
        console.log(err)
      })
    },
    editKeyId(item){
      item.noEdit=false;
    },
    updateKey(item){
      if(!this.editInput){
        item.noEdit=true;
      }else{
        updateOtherUser({userid:this.checkUserId,other_id:item.other_id,key_id:this.editInput.trim()}).then(res=>{
          if(res.code===200){
            item.noEdit=true;
            item.key_id=this.editInput;
            this.$message({
              type:'success',
              message:res.message
            })
          }
        })
      }
    },
    handleEdit(type, row, roleList) {//编辑
      this.editShow = true;
      this.editData = row;
      this.type = type;
      this.roleList = roleList;
    },
    editSuccess(data) {
      if (data) {
        this.getUserData();
      }
    },
    handleSelectionChange(val) {
      console.log(val);
      this.selectData = val;
      this.selectNumber = val.length;
    },
    getLinkSecret(){
      if(this.allIsWx3==='true'){
        getLinkSecret().then(res=>{
          if(res.data){
            this.input=res.data;
            this.getInput=true;
          }else{
            this.disabled=false
          }
        }).catch(err=>{
          console.log(err)
        })
      }
      this.outerVisible=true;
    },
    editTbInput(){
      this.disabled=false;
    },
    Tbparams() {//同步组织架构
      // var _this = this;
      // if(this.allIsWx3==='true'){
      //   if (_this.input?.trim()) {
      //     this.departSync()
      //   } else {
      //     this.$message({
      //       message: '请填写通讯录secret',
      //       type: 'warning'
      //     })
      //     return false
      //   }
      // }else{
      //   this.departSync()
      // }
      this.departSync()
    },
    departSync(){
      let _this=this;
      _this.outerVisible = false;
      _this.btnOptions.departLoading = true;
      _this.btnOptions.departTxt = "同步中...";
      departSync({link_secret: _this.input.trim()}).then((res) => {
        if (res.code === 200) {
          _this.getUserData();
          _this.$message({
            type: "success",
            message: res.message,
          });
        } else {
          _this.$message.error(res.message);
        }
        _this.btnOptions.departLoading = false;
        _this.btnOptions.departTxt = "从企业微信同步";

      }).catch((err) => {
        console.log(err);
        _this.outerVisible = false;
      });
    },
    async exportRelation(){
      console.log('导入')
      if(this.allIsWx3=='false'){
        console.log('zijian')
        await isTasking().then(res=>{
          if(res.data?.num>0){
            this.isMsgShow=true;
          }
        })
      }
      this.exportVisible=true;
    },
    exportInfo() {//生成模板
      this.loadingText = '模板生成中'
      this.dialogLoad = true;
      const that = this;
      if(this.allIsWx3==='true'){
        exportUser({dep_id: 1}).then(res => {
          if (res.code === 200) {
            that.isMsgShow=true;
          } else {
            that.$message({
              type: 'warning',
              message: '未获取到数据，请稍后重试'
            })
            console.log(res)
          }
          that.dialogLoad = false;
          that.downLoadModel=true;
        }).catch(err => {
          this.dialogLoad = false
          console.log(err)
        })
      }else{
        exportUserZj({dep_id: 1}).then(res=>{
          that.isMsgShow=true;
          that.dialogLoad = false;
          that.downLoadModel=true;
        })
      }


    },
    goToTask(){
      this.$router.push('/home/set/asynchronous')
    },
    setTimer(item) {
      const that=this;
      let timer = setInterval(() => {
        getExportId({jobid: item.jobid}).then(res => {
          if (res.code === 200) {
            that.modelList=[];
            clearInterval(timer) //清理定时任务
            that.modelList.push({
              name:'模板.zip',
              url:res.data.url
            })
            that.isDownload=true;
          } else {
            console.log(res)
          }


        }).catch(function (error) {
          console.log(error);
          clearInterval(timer) //清理定时任务
          that.dialogLoad = false;
        });

      }, 5000);

    },
    beforeAvatarUpload(file){
      this.loadingText = '数据导入中'
      const isXlx = file.type.indexOf('excel') >-1;
      const isLt18M = file.size / 1024 / 1024 < 18;
      if (!isXlx) {
        this.$message.error('上传文件只能是 xls/xlsx 格式!');
      }
      if (!isLt18M) {
        this.$message.error('上传文件大小不能超过 18MB!');
      }
        return isXlx && isLt18M
    },
    uploadFile(thisfile){
      this.dialogLoad=true;
      let data = new FormData();

      this.file2Xce(thisfile.file).then(tabJson => {
        if (tabJson && tabJson.length > 0) {
          let tableName = []
          let tableDate = []

          tabJson[1].sheet.map((res,i)=>{
            if(i==0){ tableName = Object.keys(res) } // 装头

            if(((i % 200) == 0)&&(i!=0)){
              this.tableSubmit(tableName,tableDate,thisfile.file) // 提交数据
              tableDate = [] // 清空
            }else{
              tableDate.push(Object.values(res)) // 装数据
            }
          })
          
          this.tableSubmit(tableName,tableDate,thisfile.file) // 提交数据
        }
      })
    },
    // 表格数据提交
    tableSubmit(tableName,tableDate,file){
      this.$axios.post('/importUserRelations',{
        title: tableName,
        data: tableDate
      }).then(res=>{
        if(res.code===200){
          this.$message({ type:'success', message:res.message })
          this.fileName=file.name;
          this.fileUrl=1;
        }
        this.dialogLoad=false;
      }).catch(err=>{
        this.dialogLoad=false;
      })
    },
    // 读取文件数据
    file2Xce(file) {
      return new Promise(function(resolve, reject) {
        const reader = new FileReader()
        reader.onload = function(e) {
          const data = e.target.result
          this.wb = XLSX.read(data, {
            type: 'binary'
          })
          const result = []
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            })
          })
          resolve(result)
        }
        // reader.readAsBinaryString(file.raw)
        reader.readAsBinaryString(file) // 传统input方法
      })
    },
    //编辑
    editTable(scope){
     
     
      let stringData = JSON.stringify(scope) 
      this.enterpriseInfot = JSON.parse(stringData) 
      console.log(scope,'scope')
      console.log(this.enterpriseInfot,'this.enterpriseInfot')
      this.dialogVisible = true
    },
    deleteTable(scope){
      delRelation({id:scope.id}).then(res=>{
        if(res.code == 200){
          this.$message({ type:'success', message:res.message })
          this.getUserData();
        }else{
           this.$message.error(res.message);
        }
      })
      this.deleteVisible = false
    },
    //开关
    changeCommon_userid(e){
      this.enterpriseInfot.common_userid = e
    },
     // 确定
    handClickSetOtherRelation(){
      let post = {
        id:this.enterpriseInfot.id,
        name:this.enterpriseInfot.name,
        common_userid:this.enterpriseInfot.common_userid,
      }
      setOtherRelation(post).then(res=>{
        if(res.code == 200){
          this.$message({ type:'success', message:res.message })
          this.$router.go(0)
          // this.getUserData();
          this.dialogVisible = false
        }else{
          this.$message.error(res.message);
        }
      })
    }
  },

};
</script>
<style lang="less" scoped>
.user-list_box {
  margin-left:250px;
  padding:24px 24px;
  border-left: 1px solid #E6E6E6;
  background: #fff;
  min-height: 800px;
  .main-msg{
    //overflow: auto;
  }


  .role {
    width: 196px;
  }

  .synch {
    color: #646666;
  }

  .btn {
    margin-bottom: 10px;
    border-bottom: 1px solid #d8dfe6;
  }

  .title {
    line-height: 40px;
    font-size: 14.5px;
  }

  .el-form {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #ECEFF4;
    height: 48px;
    padding-right: 14px;
    margin-top: 14px;

    /deep/ .el-form-item {
      margin-bottom: 2px;
    }

    /deep/ .sel-role {
      width: 120px;
      margin-right: 10px;

      .el-form-item__content {
        width: 120px;

        .el-select {
          width: 120px;

          .el-input__inner {
            width: 120px;
          }
        }
      }
    }

    /deep/ .sel-name {
      width: 100px;
      margin-right: 10px;

      /deep/ .el-form-item__content {
        width: 100px;

        .el-input {
          width: 100px;
        }
      }
    }

    .sel-key {
      width: 160px;
      position: relative;

      .search-btn {
        position: absolute;
        right: 12px;
        top: 11px;
        cursor: pointer;
      }
    }
  }

  /deep/ .el-table {
    font-size: 12px;
  }

  .right-btn {
    text-align: right;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .pagination {
    padding-top: 20px;
    text-align: right;
  }
}
//去尾
/deep/ .el-table {
  //min-height: 350px;
}
/deep/ .el-table td,
.el-table th {
  text-overflow: clip !important;
}
/deep/ .el-table td .cell {
  text-overflow: clip !important;
}
/deep/ .el-table th .cell {
  text-overflow: clip !important;
}
.icon-more {
  cursor: pointer;
  font-size: 18px;
}
.el-dropdown-menu__item {
  padding: 0 33px;
}
/deep/.export-dialog{
  .el-dialog__header{
    padding: 13px 20px 10px;
    border-bottom: 1px solid #e9e9e9;
    .el-dialog__title{
      font-size: 16px;
      font-weight: 500;
    }
  }
  .el-dialog__body{
    padding: 4px 32px 10px;
  }
  .tips{
    padding-left: 20px;
    height: 52px;
    background: #FFFBE6;
    border: 1px solid #FFE58F;
    border-radius: 2px;

    div{
    color: rgba(0, 0, 0, 0.65);
      padding-right: 20px;
    }
  }
  .upload-xlx{
    margin:24px 0 14px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding:34px 0;
    border: 1px solid #DBDBDB;
    .upload-this{
      margin:16px 0 6px;
    }
    .xlx-name{
      padding: 19px 0 13px;
    }
    .upload-btn{
      width: 96px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border: 1px solid #C4C4C4;
      border-radius: 2px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.65);
      margin:0 auto;
    }
    .support{
      margin-bottom: 20px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .el-upload-dragger{
    border:none;
    height: 180px;
  }
  /deep/.el-upload-dragger{
    border:none;
    height: 180px;
  }
  .model-title{
    margin-bottom: 6px;
  }
  .model-list{
    padding:0 34px 0 9px;

    height: 29px;
    &:hover{
      background: #F2F3F5;
    }

    .download-xlx{
      color: #406080;
      cursor: pointer;
    }
  }
}
//同步组织架构弹窗
/deep/.tb-dialog  {
  .el-dialog__body{
    padding: 30px;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
  }
  /deep/ .el-dialog .el-dialog__footer {
    padding: 10px 20px;
  }

}
/deep/.el-drawer__wrapper{
  top:57px;
}
/deep/.el-drawer{
  &:focus{
    outline: none;
  }
  #el-drawer__title{
    margin-bottom:0;
    padding:9px 24px;
    span{
      font-weight: bold;
      &:focus{
        outline: none;
      }
    }
  }
  .drawer-main{
    .drawer-top{
      width: 100%;
      height: 108px;
      padding: 30px 0 0 32px;
      background: #DFE9F5;
      .top-username{
        margin-top: 8px;
      }
    }
    .system-title{
      color:#808080;
      padding: 20px 25px 13px;
    }
    .system-list{
      padding: 0 25px;
      height:calc(100vh - 360px);
      overflow: hidden;
      overflow-y: auto;
      .system-item{
        font-size: 14px;
        height: 38px;
        line-height: 38px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        .system-name{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 60%;
        }
        .system-keyId{
          //position: relative;
          .el-icon-edit{
            //position: absolute;
            //right: 9px;
            //top:12px;
            color:#999999;
            display: none;
          }
          &:hover{
            .el-icon-edit{
              display: inline-block;
              color:#0082EF;
            }

          }
        }
      }
      .normal{
        color:#000;
      }
      .disable{
        color:#ccc;
      }
    }
    .system-list::-webkit-scrollbar {
      width: 0;
    }

  }
  .demo-drawer__footer{
    position: fixed;
    bottom: 0;
    width: 320px;
    border-top: 1px solid #DBDBDB;
    .drawer-cancel,.drawer-sure{
      width: 50%;
      height: 52px;
      line-height: 52px;
      text-align: center;
      cursor: pointer;
    }
    .drawer-sure{
      background: #DFE9F5;
    }
  }
}

.secret-dialog{
  /deep/.el-dialog__body{
    padding:20px 20px 10px;
  }
}

.TBbox {
  width: 100%;
  // border: 1px solid #000;
}

.TBbox_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TBbox_text:hover {
  cursor: pointer;
}

.TBbox_input {
  padding: 10px 0 10px 0;
  box-sizing: border-box;
}

//弹窗内层
.TBbox-in {
  display: flex;
  align-items: center;

  .tb-img {
    width: 93%;
  }
}

.i-lift,
.i-right {
  width: 80px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: rgba(96, 99, 102, 1);
}

.i-lift,
.i-right:hover {
  cursor: pointer;
}

.TBbox-in-text {
  padding-left: 50px;
  box-sizing: border-box;
}

.TBbox-in-text > h2 {
  padding: 10px 0 10px 0;
  box-sizing: border-box;
}
</style>
